import React, { useState } from "react"
import { ButtonResult, ButtonType, PopupSize, popup, updateMultipleCartKitItems } from "ui";
import { Inventories } from "ui/src/components/Inventories/Inventories";
import * as styles from './ServiceKitBundle.module.scss';
import cn from 'classnames';
import { BundleItem } from "./BundleItem";
import { KitBundle, BundleItemEntry } from "../../types";
import { BuyAllButton } from "./BuyAllButton";

export const ServiceKitBundle = ({ kitBundle }: { kitBundle: KitBundle }) => {

    const translations = (window as any).app.preloadState.translation;

    const isInInventory = kitBundle.inventory?.warehouse?.isAvailable ?? false;
    const isInBranch = kitBundle.inventory?.branch?.isAvailable ?? false;
    const [areChildrenVisible, setChildrenVisibility] = useState(false);

    const buyAll = async () => {
        var codesWithQuantity = kitBundle.bundleEntries.map(entry => ({
            code: entry.code,
            quantity: entry.quantity + entry.quantityInBundle,
            bundleCode: kitBundle.code,
            kitCode: kitBundle.kitCode,
            serialNumber: entry.serialNumber
        }));

        await updateMultipleCartKitItems(codesWithQuantity);
        location.reload();
    }

    const showInventoryPopup = (item: BundleItemEntry, ev: React.MouseEvent) => {

        ev.preventDefault();

        popup(
            translations["inventoryPopup.stockStatusAtFacility"],
            <Inventories itemCode={item.code} itemDisplayName={item.displayName} />,
            [
                { label: translations["inventoryPopup.cancel"], result: ButtonResult.Cancel, type: ButtonType.Primary }
            ],
            PopupSize.Large
        );
    }

    return <div className={styles.serviceKitBundle}>
        <div className={styles.wrapper}>
            <div className={styles.group}>
                <div onClick={() => { setChildrenVisibility(!areChildrenVisible) } } className={styles.chevrons}>
                </div>
                <img className={cn("objectFitContain")} src={kitBundle.imageUrl + "?width=160"} alt={kitBundle.displayName} width="80" />
                <div className={styles.infoBlock}>
                    <p className={styles.title}>{kitBundle.displayName}</p>
                    <p className={cn(styles.kitCode, "firstLetterCapital")}>
                        {kitBundle.kitCode && `${translations["serviceKitBundle.kitCode"]}: ${kitBundle.code}`}{" "}
                    </p>
                    <p className={styles.additionalInfo}>
                        {<span className={"d-inline-block firstLetterCapital"}>{translations["serviceKitBundle.kitContains"]}: {kitBundle.kitContains}</span>}
                        <span
                            className={cn("d-md-none", {
                                [styles.close]: !isInInventory,
                                [styles.truck]: isInInventory,
                            })}
                        >
                            {isInInventory ? <span dangerouslySetInnerHTML={{ __html: kitBundle.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                        </span>
                        <span
                            className={cn(styles.inventoryIndicator, "d-md-none", {
                                [styles.check]: isInBranch,
                                [styles.close]: !isInBranch,
                            })}
                        >
                            {kitBundle.inventory?.branch?.branchName ?? translations["inventories.selectBranch"]}
                        </span>
                    </p>
                </div>
            </div>
            <div className={styles.prices}>
                {kitBundle.percentageDiscount > 0 && (
                    <div>
                        <p className={styles.discountedPrice}>{kitBundle.discountedPriceTotal}</p>
                    </div>
                )}
                <div>
                    <p
                        className={cn({
                            [styles.boldText]: kitBundle.percentageDiscount <= 0,
                            [styles.originalPrice]: kitBundle.percentageDiscount > 0,
                            [styles.discountedPrice]: kitBundle.percentageDiscount > 0,
                            [styles.strike]: kitBundle.percentageDiscount > 0,
                            [styles.smallFont]: kitBundle.percentageDiscount > 0
                        })}
                    >
                        <span>{kitBundle.priceTotal}</span>
                    </p>
                    <span className={cn(styles.md_inline, styles.discountedPrice)}>{kitBundle.discountedPriceTotal ?? kitBundle.priceTotal}</span>
                </div>
                {kitBundle.percentageDiscount > 0 && (
                    <>
                        <div>
                            <span className={cn(styles.md_inline, styles.strike, styles.secondary)}>{kitBundle.priceTotal}</span>
                        </div>
                        <div>
                            <div className={cn("firstLetterCapital d-inline-block", styles.secondary)}>{translations["sparePartItem.discount"]}:</div>
                            <div className="d-inline-block">
                                <span className={styles.discount}>-{kitBundle.percentageDiscount}%</span>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div>
                <span
                    className={cn("d-none d-md-block", styles.smallFont, {
                        [styles.close]: !isInInventory,
                        [styles.truck]: isInInventory,
                    })}
                >
                    {isInInventory ? <span dangerouslySetInnerHTML={{ __html: kitBundle.inventory?.warehouse?.availabilityDisplay ?? "" }}></span> : <span className={"d-inline-block firstLetterCapital"}>{translations["inventoryPopup.notInStock"]}</span>}
                </span>
                <span
                    className={cn(styles.inventoryIndicator, "d-none d-md-block", styles.smallFont, styles.smallMarginTop, {
                        [styles.check]: isInBranch,
                        [styles.close]: !isInBranch,
                    })}
                >
                    {kitBundle.inventory?.branch?.branchName ?? translations["inventories.selectBranch"]}
                </span>
            </div>
            <div>
                <BuyAllButton
                    key={kitBundle.code}
                    showLogin={kitBundle.showLogin}
                    showNotAuthorized={kitBundle.showNotAuthorized}
                    showContactSupport={kitBundle.showContactSupport}
                    showRequestQuotationByEmail={kitBundle.showRequestQuotationByEmail}
                    showBuyButton={kitBundle.showBuyButton}
                    requestQuotationByEmail={kitBundle.requestQuotationByEmail!}
                    contactSupportLink={kitBundle.contactSupportLink!}
                    buyAll={buyAll}
                />
            </div>
        </div>
        {areChildrenVisible && kitBundle.bundleEntries.map(entry => <BundleItem key={entry.code} bundleItemEntry={entry} showInventoryPopup={showInventoryPopup} />)}
    </div>
}